import PropTypes from 'prop-types';
import Icon from './Icon';

export default function IcVideo({variant, className, stroke, fill}) {
  const VARIANTS = {
    multi: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.43977 21.5601C11.5898 19.3701 19.5798 14.7901 19.5798 12.0001C19.5798 9.21005 11.5798 4.64005 7.43977 2.45005C6.31977 1.86005 5.00977 2.68005 5.00977 3.94005V20.0701C5.00977 21.3401 6.31977 22.1501 7.43977 21.5601Z"
          fill={fill}
        />
        <path
          d="M6.66 22.75C6.19 22.75 5.72 22.62 5.3 22.37C4.49 21.88 4 21.02 4 20.06V3.94004C4.01 2.98004 4.49 2.12004 5.31 1.63004C6.11 1.15004 7.08 1.12004 7.91 1.56004C16.32 6.00004 20.58 9.51004 20.58 12C20.58 14.49 16.32 18 7.91 22.44C7.52 22.65 7.09 22.75 6.67 22.75H6.66ZM6.66 3.25004C6.52 3.25004 6.4 3.30004 6.33 3.34004C6.23 3.40004 6 3.58004 6 3.93004V20.06C6 20.42 6.23 20.6 6.33 20.66C6.45 20.73 6.68 20.82 6.96 20.67C14.98 16.43 18.57 13.26 18.57 12C18.57 10.74 14.99 7.57004 6.97 3.33004C6.86 3.27004 6.75 3.25004 6.66 3.25004Z"
          fill={stroke}
        />
      </svg>
    ),
    single: (
      <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.43977 21.5601C11.5898 19.3701 19.5798 14.7901 19.5798 12.0001C19.5798 9.21005 11.5798 4.64005 7.43977 2.45005C6.31977 1.86005 5.00977 2.68005 5.00977 3.94005V20.0701C5.00977 21.3401 6.31977 22.1501 7.43977 21.5601Z" />
        <path d="M6.66 22.75C6.19 22.75 5.72 22.62 5.3 22.37C4.49 21.88 4 21.02 4 20.06V3.94004C4.01 2.98004 4.49 2.12004 5.31 1.63004C6.11 1.15004 7.08 1.12004 7.91 1.56004C16.32 6.00004 20.58 9.51004 20.58 12C20.58 14.49 16.32 18 7.91 22.44C7.52 22.65 7.09 22.75 6.67 22.75H6.66ZM6.66 3.25004C6.52 3.25004 6.4 3.30004 6.33 3.34004C6.23 3.40004 6 3.58004 6 3.93004V20.06C6 20.42 6.23 20.6 6.33 20.66C6.45 20.73 6.68 20.82 6.96 20.67C14.98 16.43 18.57 13.26 18.57 12C18.57 10.74 14.99 7.57004 6.97 3.33004C6.86 3.27004 6.75 3.25004 6.66 3.25004Z" />
      </svg>
    ),
  };

  return (
    <Icon className={className} label="Product Video">
      {VARIANTS[variant]}
    </Icon>
  );
}

IcVideo.propTypes = {
  className: Icon.propTypes.className,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  variant: PropTypes.string,
};

IcVideo.defaultProps = {
  className: '',
  stroke: '#182C53',
  fill: '#E8EDF4',
  variant: 'single',
};
